import app from "@/main";
import {DosyaBorclu} from "../uyap/DosyaBorclu";
import {MessageLogger} from "../takip_ac/MessageLogger";
import {AvukatIcraTalepEvrakiGonderCevap} from "../uyap/AvukatIcraTalepEvrakiGonder";
import {saveAs} from "file-saver";
import {HacizGrupKoduEnum} from "../uyap/enum/HacizGrupKoduEnum";
import TakipDosya from "../uyap/classes/TakipDosya";

export default class HacizInjectable extends MessageLogger {
    talepBilgileri: any[] = [];
    dosyaBloc: TakipDosya;
    borcluBloc: DosyaBorclu;
    evrakTutar: any = 0;
    hacizEvrakImzaliBlob: Blob = new Blob();
    hacizEvrakImzasizBlob: Blob = new Blob();

    constructor(borclu: any, dosya: TakipDosya) {
        super();
        this.borcluBloc = borclu;
        this.dosyaBloc = dosya;
        this.talepBilgileri = [];
        this.evrakTutar = 0;
    }

    hacizEvrakHazirla(): Promise<void> {
        console.log("TALEP BILGILERI,", this.talepBilgileri);
        return new Promise(async (resolve, reject) => {
            try {
                this.addMessage("info", "Haciz Evrak Hazırlanıyor..");
                this.hacizEvrakImzasizBlob = await app.$uyap.IcraTalepEvrakHazirla().run({
                        dosyaId: this.dosyaBloc.dosyaId,
                        dosyaEsasNo: '',
                        filename: this.dosyaBloc.dosyaNo,
                        kisiKurumId: this.borcluBloc.kisiKurumId,
                        talepBilgileri: JSON.stringify(this.talepBilgileri)
                });
                this.addMessage("success", "Haciz evrak imzasız blob hazırlandı..");
                return resolve();
            } catch (e) {
                this.addMessage("error", "Haciz evrak imzasız blob HATA :" + e.message);
                return reject(e);
            }
        })
    }

    hacizEvrakImzala(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            try {
                this.addMessage("info", "Haciz Evrak İmzalanıyor...");
                let b64Udf = await app.$imza.dokumanImzala(this.hacizEvrakImzasizBlob);
                this.hacizEvrakImzaliBlob = app.$uyapHelper.base64toBlob(b64Udf, "application/octet-stream");
                //saveAs(this.hacizEvrakImzaliBlob, "imzali.udf");
                //saveAs(this.hacizEvrakImzasizBlob, "imzasiz.udf");
                this.addMessage("success", "Haciz Evrak İmzalandı...");
                return resolve();
            } catch (e) {
                this.addMessage("info", "Haciz Evrak İmzalama HATA *" + e.toString());
                return reject(e);
            }
        })
    }

    hacizEvrakIndir() {
        saveAs(this.hacizEvrakImzasizBlob, "HazirlananEvrak.udf");
    }

    gonder(): Promise<AvukatIcraTalepEvrakiGonderCevap> {
        console.log("HACİZ EVRAK::gonder");
        return new Promise(async (resolve, reject) => {
            try {
                this.addMessage("info", "Haciz Evrak Gönderiliyor..");
                let form = new FormData();
                let dosyaAdi = this.dosyaBloc.dosyaNo.replace('/', '_') + "_imzali.udf";
                let hacizTalepItems = [
                    {
                        "id": 1,
                        "evrakTuruOptionDVO": {
                            "tur": "ICR_AVUKAT_PORTAL_HACIZ_TALEP",
                            "id": "ICR_AVUKAT_PORTAL_HACIZ_TALEP",
                            "label": "Haciz Talebi",
                            "mandatory": true,
                            "max": 1,
                            "ekEvrakMax": 5
                        },
                        "tur": "ICR_AVUKAT_PORTAL_HACIZ_TALEP",
                        "turAciklama": "Haciz Talebi",
                        "mandatory": true,
                        "file": {},
                        "path": "C:\\fakepath\\" + dosyaAdi,
                        "kullaniciEvrakAciklama": "Haciz Talebi",
                        "parentId": -1,
                        "fileId": 1
                    }
                ];
                let genelTalepItems = [{
                    "id": 1,
                    "evrakTuruOptionDVO": {
                        "tur": "ICR_AVUKAT_PORTAL_GENEL_TALEP",
                        "id": "ICR_AVUKAT_PORTAL_GENEL_TALEP",
                        "label": "Diğer Talebi",
                        "mandatory": true,
                        "max": 1,
                        "ekEvrakMax": 5
                    },
                    "tur": "ICR_AVUKAT_PORTAL_GENEL_TALEP",
                    "turAciklama": "Diğer Talebi",
                    "mandatory": true,
                    "file": {},
                    "path": "C:\\fakepath\\" + dosyaAdi,
                    "kullaniciEvrakAciklama": "Diğer Talebi",
                    "parentId": -1,
                    "fileId": 1
                }];

                let tebligatTalepItems = [{
                    "id": 1,
                    "evrakTuruOptionDVO": {
                        "tur": "ICR_AVUKAT_PORTAL_TEBLIGAT_TALEP",
                        "id": "ICR_AVUKAT_PORTAL_TEBLIGAT_TALEP",
                        "label": "Tebligat Talebi",
                        "mandatory": true,
                        "max": 1,
                        "ekEvrakMax": 5
                    },
                    "tur": "ICR_AVUKAT_PORTAL_TEBLIGAT_TALEP",
                    "turAciklama": "Tebligat Talebi",
                    "mandatory": true,
                    "file": {},
                    "path": "C:\\fakepath\\" + dosyaAdi,
                    "kullaniciEvrakAciklama": "Tebligat Talebi",
                    "parentId": -1,
                    "fileId": 1
                }]

                let selectedGrupKodu = this.talepBilgileri[0].grupKodu;
                let selectedTalepItems = {};

                if (selectedGrupKodu == HacizGrupKoduEnum.Haciz)
                    selectedTalepItems = hacizTalepItems;
                if (selectedGrupKodu == HacizGrupKoduEnum.Tebligat)
                    selectedTalepItems = tebligatTalepItems;
                if (selectedGrupKodu == HacizGrupKoduEnum.Genel)
                    selectedTalepItems = genelTalepItems;

                form.append('file_1', this.hacizEvrakImzaliBlob, dosyaAdi);
                form.append('items', JSON.stringify(selectedTalepItems));
                form.append('dosyaId', this.dosyaBloc.dosyaId);
                form.append('kisiKurumId', this.borcluBloc.kisiKurumId.toString());
                form.append('tutar', parseFloat(this.evrakTutar).toFixed(2));
                form.append('talepGrupId', this.talepBilgileri[0].grupKodu);
                if (selectedGrupKodu == HacizGrupKoduEnum.Genel) {
                    // @ts-ignore
                    form.append('avansTutariDusulsunMu', false);
                }
                form.append('talepBilgileri', JSON.stringify(this.talepBilgileri));
                console.log("before evrak tutar", this.evrakTutar);
                if (this.evrakTutar > 0) {
                    form.append('vakifbankHesapBilgileri', 'null');
                    form.append('vakifbankOdemeIstekBilgileri', 'null');
                    form.append('smsSifre', 'null');
                    form.append('odemeTipi', '2');
                }

                let result = await app.$uyap.AvukatIcraTalepEvrakiGonder().run({
                    formData: true,
                    data: form
                })
                this.addMessage("success", "Haciz Evrak Gönderimi Başarılı..");
                return resolve(result);
            } catch (e) {
                this.addMessage("error", "Haciz Evrak Gönderimi Hatalı. Hata :" + e.message);
                return reject(e);
            }
        })

    }

    getHacizEvrakImzasiz() {
        return this.hacizEvrakImzasizBlob;
    }

    getHacizEvrakImzali() {
        return this.hacizEvrakImzaliBlob;
    }
}
