import {UyapRequest} from "./UyapRequest";
import {ISorguSonuc} from "./CommonInterfaces";
import app from "@/main";


export interface BorcluIskiTalep {
    dosyaId: string|number,
    kisiKurumId: number,
    sorguBakiye: number | string,
}

export interface BorcluIskiCevap extends ISorguSonuc<Array<IIskiSonuc>>{
}

export interface IIskiSonuc{
    adi?: string,
    adres?: string,
    aktifPasif?: string,
    babaAdi: string,
    banka?: string,
    soyadi?: string,
    sozlesmeTarihi?: string,
    tcKimlikNo?: string,
    telefon: string,
    anneAdi: string,
    dogumTarihi: string,
    dogumYeri: string,
    hata?: string
}

export default class BorcluIski extends UyapRequest<BorcluIskiTalep, BorcluIskiCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "borcluIski_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getMockResponse(): any {
        //var
        return {"borcluBilgileri":{"kisiTumDVO":{"olumKaydi":false,"isAvukat":false,"isNoter":false,"isTarafUyari":false,"isHakimSavci":false,"mernisDegisiklikVarmi":false,"mernisDegisiklikNedeni":"","sorguTuru":0,"tcKimlikNo":"4********10","adi":"AH**** MÜ***","soyadi":"YA*******","babaAdi":"A*********","anaAdi":"Ş*****","dogumTarihiStr":"1957","aktifMernisAdresiSorgusu":true,"hasOlumKaydi":false},"kisiKurumId":28698317,"turu":0},"sorgulayanBilgileri":"Av.NİHAN ERDOĞAN","portalTuru":2,"dosyaOzetDVO":{"birimDVO":{"birimAdi":"Ankara 2. Genel İcra Dairesi","birimId":"1000963","orgKodu":"1.04.011.000.6037","duzey1":"1","duzey2":"04","duzey3":"011","duzey4":"000","duzey5":"6037","birimTuru1":"11","birimTuru2":"1101","birimTuru3":"1199","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},"rolIdList":[],"isBirimKapali":false,"dosyaTurKod":35,"dosyaTurAd":"İcra Dosyası","dosyaNo":"2023/27440","dosyaNoYil":2023,"dosyaSira":27440,"dosyaId":"013@RlwAzAkR3QHUdOJ2IhqLZKTkiOkalb89ahmPtdVCZdrc4wQuMWZgOI0MqPJT","dosyaAcilisTarihi":"08/07/2023 10:35","genelDosyaDurumu":"Açık","genelDosyaDurumuAsInteger":0,"taraflar":"ABDULSELAM DENİZ, AHMETİ MÜNÜR YALÇINALP","vekilId":0,"vekilMi":false,"vekilHazineAvukatiMi":false},"sorguTarihi":"24/04/2024 11:35","sorguSonucDVO":[{"adi":"AHMETİ MÜNÜR","adres":"KAYIŞDAĞI MAH NUSRET SK DISKAPI_NO1:106  DISKAPI_NO2: Site Bina No: - Ek Bina No:- Daire:D003 ATAŞEHİR","aktifPasif":"Pasif","babaAdi":"ABDULKADİR","banka":"YOK","soyadi":"YALÇINALP","sozlesmeTarihi":"19/12/2011","tcKimlikNo":"47161224210","telefon":"null","anneAdi":"ŞADİYE","dogumTarihi":"25/08/1957","dogumYeri":"SİVAS"}],"sorgulamaTuru":"İSKİ","sorguSonucu":true,"hasError":false,"hasData":false,"isMock":false};
        // yok
        return {"borcluBilgileri":{"kisiTumDVO":{"olumKaydi":false,"isAvukat":false,"isNoter":false,"isTarafUyari":false,"isHakimSavci":false,"mernisDegisiklikVarmi":false,"mernisDegisiklikNedeni":"","sorguTuru":0,"tcKimlikNo":"3********86","adi":"AL*","soyadi":"ŞA*","babaAdi":"K**** M*****","anaAdi":"E****","dogumTarihiStr":"1977","aktifMernisAdresiSorgusu":true,"hasOlumKaydi":false},"kisiKurumId":25392813,"turu":0},"sorgulayanBilgileri":"Av.NİHAN ERDOĞAN","portalTuru":2,"dosyaOzetDVO":{"birimDVO":{"birimAdi":"Ankara 5. Genel İcra Dairesi","birimId":"1000950","orgKodu":"1.04.011.000.6040","duzey1":"1","duzey2":"04","duzey3":"011","duzey4":"000","duzey5":"6040","birimTuru1":"11","birimTuru2":"1101","birimTuru3":"1199","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},"rolIdList":[],"isBirimKapali":false,"dosyaTurKod":35,"dosyaTurAd":"İcra Dosyası","dosyaNo":"2013/14223","dosyaNoYil":2013,"dosyaSira":14223,"dosyaId":"y4KfCrRgrTTAGmn1zpLkt@kjKY1OHWNkP@nVHmRKz831bU0YG1R0mtxpO6iCdlKN","dosyaAcilisTarihi":"05/09/2013 13:42","genelDosyaDurumu":"Açık","genelDosyaDurumuAsInteger":0,"taraflar":"ALİ ŞAN","vekilId":0,"vekilMi":false,"vekilHazineAvukatiMi":false},"sorguTarihi":"24/04/2024 11:31","sorguSonucDVO":[{"babaAdi":"","telefon":"","anneAdi":"","dogumTarihi":"","dogumYeri":"","hata":"java.lang.Exception: Kayit Bulunamadi"}],"sorgulamaTuru":"İSKİ","sorguSonucu":false,"hasError":false,"hasData":false,"isMock":false};
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'borclu_bilgileri_goruntule_iski.ajx';
    }

    async parse(data: any): Promise<BorcluIskiCevap> {
        data.message = "Kişiye Ait İSKİ kayıtlarına göre İstanbul adresi kaydı " + (data.sorguSonucu ? 'var' : 'yok');
        return data;
    }
}

