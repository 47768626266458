import {UyapRequest} from "./UyapRequest";
import {ISorguSonuc} from "./CommonInterfaces";
import app from "@/main";

export interface BorcluSgkHacizTalep {
    dosyaId: string|number,
    kisiKurumId: number
}

export interface BorcluSgkHacizCevap extends ISorguSonuc<Array<ISgkHacizSonuc>>{
}

export interface ISgkHacizSonuc{
    kisiTipString: string,
    show: boolean
}

export default class BorcluSgkHaciz extends UyapRequest<BorcluSgkHacizTalep, BorcluSgkHacizCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "borcluSgkHaciz_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getMockResponse(): any {
        // var
        return {"borcluBilgileri":{"kisiTumDVO":{"olumKaydi":false,"isAvukat":false,"isNoter":false,"isTarafUyari":false,"isHakimSavci":false,"mernisDegisiklikVarmi":false,"mernisDegisiklikNedeni":"","sorguTuru":0,"tcKimlikNo":"2********14","adi":"MA****","soyadi":"KI********","babaAdi":"Y****","anaAdi":"S***","dogumTarihiStr":"1965","aktifMernisAdresiSorgusu":true,"hasOlumKaydi":false},"kisiKurumId":4000003527,"turu":0},"sorgulayanBilgileri":"Av.NİHAN ERDOĞAN","portalTuru":2,"dosyaOzetDVO":{"birimDVO":{"birimAdi":"Ankara 7. Genel İcra Dairesi","birimId":"1000942","orgKodu":"1.04.011.000.6042","duzey1":"1","duzey2":"04","duzey3":"011","duzey4":"000","duzey5":"6042","birimTuru1":"11","birimTuru2":"1101","birimTuru3":"1199","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},"rolIdList":[],"isBirimKapali":false,"dosyaTurKod":35,"dosyaTurAd":"İcra Dosyası","dosyaNo":"2023/97995","dosyaNoYil":2023,"dosyaSira":97995,"dosyaId":"IplI9eWZYxV5k0SnSHvyziH1ED@ZrLvwkY1KBoMt2ykV@2WGvXeNcOmsu5nebXCA","dosyaAcilisTarihi":"11/11/2023 04:25","genelDosyaDurumu":"Açık","genelDosyaDurumuAsInteger":0,"taraflar":"MAZHAR KILIÇASLAN","vekilId":0,"vekilMi":false,"vekilHazineAvukatiMi":false},"sorguTarihi":"25/04/2024 10:38","sorguSonucDVO":[{"kisiTipString":"Bağ-Kur Sigortalı Aktif","show":true},{"kisiTipString":"Bağ-Kur Sigortalısı","show":false},{"kisiTipString":"Diğer - Gerçek","show":false}],"sorgulamaTuru":"SGK HACİZ","sorguSonucu":true,"hasError":false,"hasData":true,"isMock":false}
    }


    getUrl(): string {
        return this.ImzaClientAddr + "borclu_bilgileri_goruntule_sgk_haciz.ajx";
    }

    async parse(data: any): Promise<BorcluSgkHacizCevap>{
        data.message = "SGK kaydı " + (data.sorguSonucu ? 'var' : 'yok');
        return data;
    }
}

