import {UyapRequest} from "./UyapRequest";
import {IBorcluBilgileri} from "@/plugins/uyap-plugin/uyap/CommonInterfaces";
import {DosyaBorclu} from "@/plugins/uyap-plugin/uyap/DosyaBorclu";

export interface DosyaBorclulariTalep {
    dosyaId: string | number
}

export type DosyaBorclulariCevap = Array<DosyaBorclu>

export default class DosyaBorclulari extends UyapRequest<DosyaBorclulariTalep, DosyaBorclulariCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "dosya_borclu_" + this.talepData.dosyaId
        };
    }

    getMockResponse(): any {
        return [{"kisiKurumId":119441844,"kurumDVO":{"kurumAdi":"AFET VE ACİL DURUM YÖNETİMİ BAŞKANLIĞI","vergiNo":"0080781764","veriGelisYeri":0,"faaliyetDurumu":-1,"tacirMi":"H","mersisIlKodu":0,"mersisIlceKodu":0},"turu":1}];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'dosya_borclu_list.ajx';
    }

    async parse(data: Array<IBorcluBilgileri>): Promise<DosyaBorclulariCevap> {
        let dosyaBorclulari = data.map(borclu => new DosyaBorclu(borclu));
        return dosyaBorclulari;
    }
}

