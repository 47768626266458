import {UyapRequest} from "./UyapRequest";
import app from "@/main";

export interface DosyaDetayBilgileriTalep {
    dosyaId: string | number
}

export interface DosyaDetayBilgileriCevap {
    takibinTuru: string,
    takibinSekli: string,
    takibinYolu: string,
    alacakKalemToplamTutar: number,
    alacakKalemFaizTutar: number,
    takipSonrasiMasraf: number,
    vekaletUcreti: number,
    tahsilHarci: number,
    yapilmisBorcTahsilati: number,
    takibinTuruAciklama: string,
    takibinSekliAciklama: string,
    takibinYoluAciklama: string,
    dosyaDurumu: string,
    sonuc: boolean,
    id: string,
    isCompressed: boolean,
    htmlHataKodList: Array<any>,
    isMock: boolean
}


export class DosyaDetayBilgileri extends UyapRequest<DosyaDetayBilgileriTalep, DosyaDetayBilgileriCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "DosyaDetayBilgileri_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getMockResponse(): any {
        return {"takibinTuru":"1","takibinSekli":"1","takibinYolu":"4","alacakKalemToplamTutar":9314.16,"alacakKalemFaizTutar":3111.77,"takipSonrasiMasraf":43.3,"vekaletUcreti":3600,"tahsilHarci":423.79,"yapilmisBorcTahsilati":0,"takibinTuruAciklama":"İlamsız Takip","takibinSekliAciklama":" ÖRNEK: 13  Adi Kiraya Ve Hasılat Kiralarına Ait Takip Taleplerinde Ödeme Emri -Eski No: 51","takibinYoluAciklama":"Kiralanan Gayrimenkullerin İlamsız Tahliyesi","dosyaDurumu":"Açık","sonuc":false,"id":"15613773151356537265167566701564471307237241","isCompressed":false,"htmlHataKodList":[],"isMock":false};
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'dosyaAyrintiBilgileri_brd.ajx';
    }

    async parse(data: any): Promise<DosyaDetayBilgileriCevap> {
        return data;
    }

}

export default DosyaDetayBilgileri;
