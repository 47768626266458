import {UyapRequest} from "./UyapRequest";
import app from "@/main";
import {ISorguSonuc} from "@/plugins/uyap-plugin/uyap/CommonInterfaces";

export interface BorcluGibTalep {
    dosyaId: string|number,
    kisiKurumId: number,
    sorguBakiye: number| string,
}

export interface BorcluGibCevap extends ISorguSonuc<IGibSonuc> {}

export interface IGibSonuc {
    caddeSokak: string,
    daireNo: string,
    ilKodu: string,
    ilceAdi: string,
    kapiNo: string,
    mahalleSemt: string,
}

export default class BorcluGib extends UyapRequest<BorcluGibTalep, BorcluGibCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "borcluGib_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getMockResponse(): any {
        return {"borcluBilgileri":{"kisiTumDVO":{"olumKaydi":false,"isAvukat":false,"isNoter":false,"isTarafUyari":false,"isHakimSavci":false,"mernisDegisiklikVarmi":false,"mernisDegisiklikNedeni":"","sorguTuru":0,"tcKimlikNo":"1********72","adi":"ME**** FA***","soyadi":"UÇ**","babaAdi":"Ş****","anaAdi":"G*******","dogumTarihiStr":"1972","aktifMernisAdresiSorgusu":true,"hasOlumKaydi":false},"kisiKurumId":19508806,"turu":0},"sorgulayanBilgileri":"Av.NİHAN ERDOĞAN","portalTuru":2,"dosyaOzetDVO":{"birimDVO":{"birimAdi":"Ankara 6. Genel İcra Dairesi","birimId":"1000946","orgKodu":"1.04.011.000.6041","duzey1":"1","duzey2":"04","duzey3":"011","duzey4":"000","duzey5":"6041","birimTuru1":"11","birimTuru2":"1101","birimTuru3":"1199","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},"rolIdList":[],"isBirimKapali":false,"dosyaTurKod":35,"dosyaTurAd":"İcra Dosyası","dosyaNo":"2023/38229","dosyaNoYil":2023,"dosyaSira":38229,"dosyaId":"45lTaWSJtn+Niv+bqykpfapWxlthsl1@6taepSFZBi5HZlkw2US00VjO1hnAX@W9","dosyaAcilisTarihi":"14/10/2023 14:57","genelDosyaDurumu":"Açık","genelDosyaDurumuAsInteger":0,"taraflar":"NİLAY AYDOĞMUŞ, MEHMET FAZIL UÇAR","vekilId":0,"vekilMi":false,"vekilHazineAvukatiMi":false},"sorguTarihi":"22/04/2024 16:30","sorguSonucDVO":{"caddeSokak":"1942. SK.","daireNo":"4","ilKodu":"034","ilceAdi":"ESENYURT","kapiNo":"20","mahalleSemt":"MEHTERÇEŞME MAH."},"sorgulamaTuru":"GİB","sorguSonucu":true,"hasError":false,"hasData":false,"isMock":false};
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'borclu_bilgileri_goruntule_gib_adres.ajx';
    }

    async parse(data: BorcluGibCevap): Promise<BorcluGibCevap> {
        data.message = "Kişiye ait GİB kayıtlarına göre adres kaydı " + (data.sorguSonucu ? 'var' : 'yok');
        return data;
    }
}

