import {DosyaBorcluSSKCevap, SigortaDurumKod} from "./DosyaBorcluSSK";
import {DosyaBorcluEgmCevap, DosyaBorcluEgmTalep} from "./DosyaBorcluEgm";
import {DosyaBorcluMernisCevap} from "./DosyaBorcluMernis";
import {SgkKamuCevap} from "./SgkKamu";
import {BorcluGibCevap} from "./BorcluGib";
import {DosyaBorcluBagkurCevap} from "./DosyaBorcluBagkur";
import {DosyaBorcluBankaBilgileriCevap} from "./DosyaBorcluBankaBilgileri";
import {BorcluDibCevap} from "./BorcluDib";
import {DosyaBorcluIsyeriSgkCevap} from "./DosyaBorcluIsyeriSgk";
import {BorcluTakbisSorgulamaCevap} from "./BorcluTakbisSorgulama";
import {BorcluSgkHacizCevap} from "./BorcluSgkHaciz";
import {BorcluIskiCevap} from "./BorcluIski";
import {BorcluIcraDosyasiCevap} from "./BorcluIcraDosyasi";
import {BorcluGsmCevap} from "./BorcluGsm";
import {BorcluPostaCekiCevap, BorcluPostaCekiTalep} from "./BorcluPostaCeki";
import app from "@/main";
import {DosyaBorcluHacizInterface,} from "../haciz/HacizParamsInterfaces";
import {DosyaBorcluTebligatInterface} from "../haciz/TebligatParamsInterface";
import {DigerTalepParamsInterface} from "../haciz/TopluDigerTalepParamsInterface";
import {BorcluTur, IBorcluBilgileri, IKisiTumDVO, IKurumDVO} from "./CommonInterfaces";
import {DosyaBorcluEgmMahrumiyetCevap} from "./DosyaBorcluEgmMahrumiyet";
import {SskEmekliCevap} from "@/plugins/uyap-plugin/uyap/SskEmekli";
import {BagkurEmekliCevap} from "@/plugins/uyap-plugin/uyap/BagkurEmekli";
import {SORGU_UCRETI} from "@/plugins/uyap-plugin/uyap/SorguBakiyeYukle";

export class DosyaBorclu {
    turu: BorcluTur;
    kisiKurumId: number;
    borcluBilgileri: IKisiTumDVO | IKurumDVO;
    sskAktif: boolean = false;
    ssk: DosyaBorcluSSKCevap | EmptyObject = new EmptyObject();
    egm: DosyaBorcluEgmCevap | EmptyObject = new EmptyObject();
    mernis: DosyaBorcluEgmMahrumiyetCevap | EmptyObject = new EmptyObject();
    mersis: DosyaBorcluMernisCevap | EmptyObject = new EmptyObject();
    sgkkamu: SgkKamuCevap | EmptyObject = new EmptyObject();
    gib: BorcluGibCevap | EmptyObject = new EmptyObject();
    sgkbagkur: DosyaBorcluBagkurCevap | EmptyObject = new EmptyObject();
    emekliBagkur: BagkurEmekliCevap | EmptyObject = new EmptyObject();
    emekliSsk: SskEmekliCevap | EmptyObject = new EmptyObject();
    // todo: kamu har gelince güncellenecek
    emekliKamu: SskEmekliCevap | EmptyObject = new EmptyObject();
    bankaBilgileri: DosyaBorcluBankaBilgileriCevap | EmptyObject = new EmptyObject();
    dib: BorcluDibCevap | EmptyObject = new EmptyObject();
    sgkIsyeri: DosyaBorcluIsyeriSgkCevap | EmptyObject = new EmptyObject();
    takbis: BorcluTakbisSorgulamaCevap | EmptyObject = new EmptyObject();
    sgkhaciz: BorcluSgkHacizCevap | EmptyObject = new EmptyObject();
    iski: BorcluIskiCevap | EmptyObject = new EmptyObject();
    icraDosyaKaydi: BorcluIcraDosyasiCevap | EmptyObject = new EmptyObject();
    gsm: BorcluGsmCevap | EmptyObject = new EmptyObject();
    postaCeki: BorcluPostaCekiCevap | EmptyObject = new EmptyObject();
    haciz: DosyaBorcluHacizInterface = new DosyaBorcluHacizInterface();
    tebligat: DosyaBorcluTebligatInterface = new DosyaBorcluTebligatInterface();
    digerTalep: DigerTalepParamsInterface = new DigerTalepParamsInterface();
    isKisiBool: boolean = false;
    isKurumBool: boolean = false;

    constructor(borclu: IBorcluBilgileri) {
        this.turu = borclu.turu;
        this.kisiKurumId = borclu.kisiKurumId;
        if (this.turu == BorcluTur.KISI) {
            this.borcluBilgileri = borclu.kisiTumDVO as IKisiTumDVO;
            this.isKisiBool = true;
            this.isKurumBool = false;
        } else {
            this.borcluBilgileri = borclu.kurumDVO as IKurumDVO;
            this.isKisiBool = false;
            this.isKurumBool = true;
        }
    }

    isKurum(): boolean {
        return this.turu == BorcluTur.KURUM;
    }

    isKisi(): boolean {
        return this.turu == BorcluTur.KISI;
    }

    setBorcluErrorAllFields(message: string) {
        this.ssk.message = message;
        this.egm.message = message;
        this.mernis.message = message;
        this.mersis.message = message;
        this.sgkkamu.message = message;
        this.gib.message = message;
        this.sgkbagkur.message = message;
        this.bankaBilgileri.message = message;
        this.dib.message = message;
        this.sgkIsyeri.message = message;
        this.takbis.message = message;
        this.sgkhaciz.message = message;
        this.iski.message = message;
        this.icraDosyaKaydi.message = message;
        this.gsm.message = message;
        this.postaCeki.message = message;
    }

    async kotaIslemleri(dosyaId: string, ucretliSorguyaDevamEt: boolean = false, barokarttanSorguBakiyesiYukle: boolean = false) {
        try {
            let bakiyeRes = await app.$uyap.SorguBakiyesi().run({dosyaId: dosyaId});
            if (bakiyeRes.dosyaKota.sorguKalanIslemSayisi > 0) {
                console.log('KOTA: kota dahilinde sorgu');
                return Promise.resolve();
            } else if (ucretliSorguyaDevamEt) {
                if (Number(bakiyeRes.dosyaKota.sorguBakiyesi) >= SORGU_UCRETI) {
                    await app.$uyap.SorguDosyaKontrol().run({dosyaList: dosyaId});
                    console.log('KOTA: ücretli sorgu');
                    return Promise.resolve();
                } else if (barokarttanSorguBakiyesiYukle) {
                    await app.$uyap.SorguBakiyeYukle().run({bakiye: SORGU_UCRETI, odemeTip: 2});
                    await app.$uyap.SorguDosyaKontrol().run({dosyaList: dosyaId});
                    console.log('KOTA: barokattan yükle ücretli sorgu');
                    return Promise.resolve();
                }
                console.log('KOTA: bakiye yok');
                return Promise.reject(new Error("Bakiye yetersiz."));
            } else {
                console.log('KOTA: kota kalmadı');
                return Promise.reject(new Error("Sorgu kotası kalmadı."));
            }
        } catch (e) {
            console.log('KOTA: hata', e);
            return Promise.reject(e);
        }
    }

    async queryBorcluSsk(dosyaId: string): Promise<void> {
        try {
            this.ssk = await app.$uyap.DosyaBorcluSSK().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: '',
            });
            return Promise.resolve();
        } catch (e) {
            this.ssk.message = e.message;
            return Promise.reject(e);
        }
    }

    // todo: kullanılan yerleri düzelt
    async queryEgm(dosyaId: string, ucretliSorguyaDevamEt: boolean = false, barokarttanSorguBakiyesiYukle: boolean = false): Promise<DosyaBorcluEgmCevap> {
        try {
            let reqData: DosyaBorcluEgmTalep = {
                dosyaId: dosyaId,
                kisiKurumId: this.kisiKurumId,
                pageIndex: 0,
                sorguBakiye: '',
            };
            let isDataCached = app.$uyap.DosyaBorcluEgm().isDataCached(reqData);
            if (!isDataCached)
                await this.kotaIslemleri(dosyaId, ucretliSorguyaDevamEt, barokarttanSorguBakiyesiYukle);
            this.egm = await app.$uyap.DosyaBorcluEgm().run(reqData);
            return Promise.resolve(this.egm as DosyaBorcluEgmCevap);
        } catch (e) {
            this.egm.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryEgmMahrumiyet(dosyaId: any): Promise<void> {
        try {
            await this.queryEgm(dosyaId);
            let egm = this.egm as DosyaBorcluEgmCevap;
            if (egm.sorguSonucu && egm.sorguSonucDVO) {
                for (let arac of egm.sorguSonucDVO.aracBilgileri.output) {
                    try {
                        arac.mahrumiyet = await app.$uyap.DosyaBorcluEgmMahrumiyet().run({
                            dosyaId: dosyaId,
                            kisiKurumId: this.kisiKurumId,
                            plaka: arac.plakaSifreli
                        })
                        console.log("ARAC MAHRUMIYET", arac.mahrumiyet);
                    } catch (e) {
                        // @ts-ignore
                        arac.mahrumiyet.message = e.message;
                    }
                }
            } else
                return Promise.reject(new Error("Kişiye ait araç kaydı yok"));
            return Promise.resolve();
        } catch (e) {
            console.log("MAHRUMIYET ERR", e);
            return Promise.reject(e);
        }
    }

    async queryBorcluMernis(dosyaId: string): Promise<void> {
        try {
            this.mernis = await app.$uyap.DosyaBorcluMernis().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: '',
            });
            return Promise.resolve();
        } catch (e) {
            this.mernis.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluMersis(dosyaId: string): Promise<void> {
        try {
            this.mersis = await app.$uyap.DosyaBorcluMersis().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            });
            return Promise.resolve();
        } catch (e) {
            this.mersis.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluSgkKamu(dosyaId: string): Promise<void> {
        try {
            this.sgkkamu = await app.$uyap.SgkKamu().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: '',
            });
            return Promise.resolve();
        } catch (e) {
            this.sgkkamu.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluGib(dosyaId: string): Promise<void> {
        try {
            this.gib = await app.$uyap.BorcluGib().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: '',
            });
            return Promise.resolve();
        } catch (e) {
            this.gib.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluSgkBagkur(dosyaId: string): Promise<void> {
        try {
            this.sgkbagkur = await app.$uyap.DosyaBorcluBagkur().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: '',
            });
            return Promise.resolve();
        } catch (e) {
            this.sgkbagkur.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluEmekliBagkur(dosyaId: string): Promise<void> {
        try {
            this.emekliBagkur = await app.$uyap.BagkurEmekli().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            });
            return Promise.resolve();
        } catch (e) {
            this.emekliBagkur.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluEmekliSsk(dosyaId: string): Promise<void> {
        try {
            this.emekliSsk = await app.$uyap.SskEmekli().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            });
            return Promise.resolve();
        } catch (e) {
            this.emekliSsk.message = e.message;
            return Promise.reject(e);
        }
    }

    // todo: kamu har gelince eklenecek
    async queryBorcluEmekliKamu(dosyaId: string): Promise<void> {
        try {
            this.emekliKamu = await app.$uyap.SskEmekli().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            });
            return Promise.resolve();
        } catch (e) {
            this.emekliKamu.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluDib(dosyaId: string): Promise<void> {
        try {
            this.dib = await app.$uyap.BorcluDib().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: '',
            });
            return Promise.resolve();
        } catch (e) {
            this.dib.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluSgkIsyeri(dosyaId: string): Promise<void> {
        try {
            this.sgkIsyeri = await app.$uyap.DosyaBorcluIsyeriSgk().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: '',
            });
            return Promise.resolve();
        } catch (e) {
            this.sgkIsyeri.message = e.message;
            return Promise.reject(e);
        }
    }

    // TODO: kullanılan yerleri incele
    async queryBorcluTakbis(dosyaId: string, ucretliSorguyaDevamEt: boolean = false, barokarttanSorguBakiyesiYukle: boolean = false): Promise<void> {
        try {
            let reqData = {
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
            }
            let isDataCached = app.$uyap.BorcluTakbisSorgulama().isDataCached(reqData);
            if (!isDataCached)
                await this.kotaIslemleri(dosyaId, ucretliSorguyaDevamEt, barokarttanSorguBakiyesiYukle);
            this.takbis = await app.$uyap.BorcluTakbisSorgulama().run(reqData);
            return Promise.resolve();
        } catch (e) {
            this.takbis.message = e.message;
            return Promise.reject(e);
        }
    }

    // TODO: kullanılan yerleri incele
    async queryBorcluSgkHaciz(dosyaId: string, ucretliSorguyaDevamEt: boolean = false, barokarttanSorguBakiyesiYukle: boolean = false): Promise<void> {
        try {
            let reqData = {
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
            };
            let isDataCached = app.$uyap.BorcluSgkHaciz().isDataCached(reqData);
            if (!isDataCached)
                await this.kotaIslemleri(dosyaId, ucretliSorguyaDevamEt, barokarttanSorguBakiyesiYukle);
            this.sgkhaciz = await app.$uyap.BorcluSgkHaciz().run(reqData);
            return Promise.resolve();
        } catch (e) {
            this.sgkhaciz.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluIski(dosyaId: string): Promise<void> {
        try {
            this.iski = await app.$uyap.BorcluIski().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: '',
            });
            return Promise.resolve();
        } catch (e) {
            this.iski.message = e.message;
            return Promise.reject(e);
        }
    }

    // TODO: kullanılan yerleri incele
    async queryBorcluIcraDosyaKaydi(dosyaId: string, ucretliSorguyaDevamEt: boolean = false, barokarttanSorguBakiyesiYukle: boolean = false): Promise<void> {
        try {
            let reqData = {
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
            };
            let isDataCached = app.$uyap.BorcluIcraDosyasi().isDataCached(reqData);
            if (!isDataCached)
                await this.kotaIslemleri(dosyaId, ucretliSorguyaDevamEt, barokarttanSorguBakiyesiYukle);
            this.icraDosyaKaydi = await app.$uyap.BorcluIcraDosyasi().run(reqData);
            return Promise.resolve();
        } catch (e) {
            this.icraDosyaKaydi.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBorcluGsm(dosyaId: string): Promise<void> {
        try {
            this.gsm = await app.$uyap.BorcluGsm().run({
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId,
                sorguBakiye: '',
            });
            return Promise.resolve();
        } catch (e) {
            this.gsm.message = e.message;
            return Promise.reject(e);
        }
    }

    // TODO: kullanılan yerleri incele
    async queryBorcluPostaCeki(dosyaId: string, ucretliSorguyaDevamEt: boolean = false, barokarttanSorguBakiyesiYukle: boolean = false): Promise<void> {
        try {
            let reqData: any = {
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            };
            let isDataCached = app.$uyap.BorcluPostaCeki().isDataCached(reqData);
            if (!isDataCached)
                await this.kotaIslemleri(dosyaId, ucretliSorguyaDevamEt, barokarttanSorguBakiyesiYukle);
            this.postaCeki = await app.$uyap.BorcluPostaCeki().run(reqData);
            return Promise.resolve();
        } catch (e) {
            this.postaCeki.message = e.message;
            return Promise.reject(e);
        }
    }

    // TODO: kullanılan yerleri incele
    async queryBorcluBankaBilgileri(dosyaId: string, ucretliSorguyaDevamEt: boolean = false, barokarttanSorguBakiyesiYukle: boolean = false): Promise<void> {
        try {
            let reqData = {
                kisiKurumId: this.kisiKurumId,
                dosyaId: dosyaId
            };
            let isDataCached = app.$uyap.DosyaBorcluBankaBilgileri().isDataCached(reqData);
            if (!isDataCached)
                await this.kotaIslemleri(dosyaId, ucretliSorguyaDevamEt, barokarttanSorguBakiyesiYukle);
            this.bankaBilgileri = await app.$uyap.DosyaBorcluBankaBilgileri().run(reqData);
            return Promise.resolve();
        } catch (e) {
            this.bankaBilgileri.message = e.message;
            return Promise.reject(e);
        }
    }

    async isSskDurumAktif(dosyaId: any): Promise<boolean> {
        await this.queryBorcluSsk(dosyaId);
        this.sskAktif = false;
        let ssk = this.ssk as DosyaBorcluSSKCevap;
        let donemYilAy = ssk.sorguSonucDVO?.sskBilgiDetay.sgkIsyeriDVO.sigortaliBilgi.donemYilAy;
        let durum = ssk.sorguSonucDVO?.sskBilgiDetay.sgkIsyeriDVO.sigortaliBilgi.durumKod;
        let aktifSayilabilecekDonem = new Date(app.$uyapHelper.getDateNow("/", "month -2", true)).getTime(); //2019/01
        let mevcutDosyaDonem = new Date(donemYilAy as string).getTime();
        if (durum == SigortaDurumKod.AKTIF) {
            if (mevcutDosyaDonem > aktifSayilabilecekDonem)
                this.sskAktif = true;
        }
        return this.sskAktif;
    }
}

class EmptyObject {
    message: string = "";
}