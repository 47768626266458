import {UyapRequest} from "./UyapRequest";
import app from "@/main";

export interface KurumSorgulamaTalep {
    mersisNo?: string,
    kurumAdi?: string,
    vergiNo?: string,
    detsisNo?: string
}

export interface KurumSorgulamaCevap {
    error: string,
    kisiKurumID: number,
    kurumAdi: string,
    vergiNo: number,
    ticaretSicilNo: number,
    ticaretSicilNoVerildigiYer: string,
    harcDurumu: number,
    kamuOzel: string,
    sskIsyeriSicilNo: string,
    mersisNo: string,
    veriGelisYeri: number,
    merkezAdresi: IAdres,
    faaliyetDurumu: number,
    ortakBilgileri: Array<OrtakBilgisi>,
    temsilciBilgileri: Array<TemsilciBilgisi>,
    tacirMi: string,
    mersisIlKodu: number,
    detsisNo: string,
    mersisIlceKodu: number
}

interface IAdres {
    ilKodu: number,
    ilAdi: string,
    ilceAdi: string,
    ilceKodu: number,
    adres: string,
    kisiKurumID: number,
    adresID: number,
    adresTuru: string,
    adresTuruAciklama: string,
    eklemeTarihi: string,
    eklenebilirAdresMi: boolean,
    kayitliAdresMi: boolean,
    aktifAdresmi: boolean,
    guncellendiMi: boolean,
    aktif: string
}

interface OrtakBilgisi {
    ortakTipi: number,
    adi: string,
    soyadi: string,
    unvan: string,
    tcKimlikNo: string
}

interface TemsilciBilgisi {
    tcKimlikNo: number,
    adi: string,
    soyadi: string,
    temsilciTipi: number,
    unvani: string,
    KisiTipiKodu: number,
    yetkiSuresiGun: number,
    yetkiSuresiAy: number,
    yetkiSuresiYil: number
}

export default class KurumSorgulama extends UyapRequest<KurumSorgulamaTalep, KurumSorgulamaCevap[]> {
    delayTime = 1000;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "kurum_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + "kurumSorgula.ajx";
    }

    async recursive(talep: KurumSorgulamaTalep): Promise<KurumSorgulamaCevap[]> {
        try {
            return await this.run({
                mersisNo: talep.mersisNo,
                kurumAdi: talep.kurumAdi,
                vergiNo: talep.vergiNo,
                detsisNo: talep.detsisNo,
            });
        } catch (e) {
            //@ts-ignore
            try {
                return await this.run({
                    mersisNo: talep.mersisNo,
                    kurumAdi: "",
                    vergiNo: "",
                    detsisNo: talep.detsisNo,
                })
            } catch (e) {
                try {
                    return await this.run({
                        mersisNo: "",
                        kurumAdi: "",
                        vergiNo: talep.vergiNo,
                        detsisNo: talep.detsisNo,
                    })
                } catch (e) {
                    try {
                        return await this.run({
                            mersisNo: "",
                            kurumAdi: talep.kurumAdi,
                            vergiNo: "",
                            detsisNo: talep.detsisNo,
                        });
                    } catch (e) {
                        return Promise.reject(e);
                    }
                }
            }
        }
    }

    getMockResponse(): any {
        return [
            {
                "kisiKurumID": 192145110,
                "kurumAdi": "ZİRVE AKÜMÜLATÖR ELEKTRİK OTOMASYON SANAYİ VE TİCARET LİMİTED ŞİRKETİ",
                "vergiNo": "8770013406",
                "ticaretSicilNo": "278",
                "ticaretSicilNoVerildigiYer": "KAYSERİ TİCARET SİCİL MÜDÜRLÜĞÜ",
                "harcDurumu": 1,
                "kamuOzel": "O",
                "sskIsyeriSicilNo": "45611",
                "mersisNo": "0998082841100011",
                "veriGelisYeri": 0,
                "merkezAdresi":
                    {
                        "ilKodu": 38,
                        "ilAdi": "KAYSERİ",
                        "ilceAdi": "KOCASİNAN",
                        "ilceKodu": 8,
                        "adres": "OYMAAĞAÇ MAH.   5068 SOKAK  No:8/C KOCASİNAN/KAYSERİ",
                        "kisiKurumID": 192145110,
                        "adresID": 205901697,
                        "adresTuru": "ADRTR00011",
                        "adresTuruAciklama": "Mersis Adresi",
                        "eklemeTarihi": "Sep 11, 2017 8:56:58 AM",
                        "eklenebilirAdresMi": false,
                        "kayitliAdresMi": false,
                        "aktifAdresmi": false,
                        "guncellendiMi": false,
                        "aktif": "E"
                    },
                "faaliyetDurumu": 1,
                "ortakBilgileri": [
                    {
                        "ortakTipi": 2,
                        "adi": "UFUK",
                        "soyadi": "DİK",
                        "unvan": "",
                        "tcKimlikNo": "13925809962"
                    },
                    {
                        "ortakTipi": 2,
                        "adi": "OSMAN",
                        "soyadi": "ERÖZ",
                        "unvan": "",
                        "tcKimlikNo": "17297420732"
                    }],
                "temsilciBilgileri": [
                    {
                        "tcKimlikNo": "13925809962",
                        "adi": "UFUK",
                        "soyadi": "DİK",
                        "temsilciTipi": 3,
                        "unvani": "",
                        "KisiTipiKodu": 0,
                        "yetkiSuresiGun": 0,
                        "yetkiSuresiAy": 0,
                        "yetkiSuresiYil": 0
                    }],
                "tacirMi": "H",
                "mersisIlKodu": 0,
                "mersisIlceKodu": 0
            }];
    }

    async parse(data: any): Promise<Array<KurumSorgulamaCevap>> {
        return data;
    }

}

