import { TakipAcStatusEnum } from "./parser/TakipAcStatusEnum";
import TakipXmlParser from "./TakipXmlParser";
import app from "@/main";
import { TevziAlCevap } from "../uyap/TevziAl";
import TakipAcInjectable from "./TakipAcInjectable";
import Ilam from "./parser/Ilam";
import VakifbankOdeme, { TebligatGideriOdeResult } from "./VakifbankOdeme";
import { OdemeTipiEnum } from "./OdemeTipleri";
import { saveAs } from "file-saver";
import { TamamlanmayanDosyalarRow } from "../uyap/TamamlanmayanDosyalar";
import { SurecNoEnum } from "../uyap/enum/SurecNoEnum";
import Args from "./parser/Args";
import { IHarcMasraf } from "../uyap/CommonInterfaces";
import { TarafRol } from "@/plugins/uyap-plugin/enum/uyap/TarafRol";
import {
  DosyaEvraklarCevap,
  IDosyaEvrak,
} from "@/plugins/uyap-plugin/uyap/DosyaEvraklar";

export default class UyapTakipAc extends TakipAcInjectable {
  status: TakipAcStatusEnum = TakipAcStatusEnum.QUERY_PENDING;
  masrafMiktar = 0;
  harcMiktar: number = 0;
  harcMasrafToplam: number = 0;
  harcMasrafList: IHarcMasraf[] = [];
  takipBilgileri = {
    dosyaEsasNo: "",
    icraDairesi: "",
  };
  tevziBilgileri!: TevziAlCevap;
  parsedFile!: TakipXmlParser;
  ekler: any = {
    ilamliDosya: undefined,
  };
  dosyaBilgileri!: TamamlanmayanDosyalarRow;
  evraklar!: DosyaEvraklarCevap;

  static fromJson(jsonData: any): UyapTakipAc {
    let uyapTakipAc = Args.staticFromJson(jsonData, new UyapTakipAc());
    uyapTakipAc.parsedFile = TakipXmlParser.fromJson(jsonData.parsedFile);
    return uyapTakipAc;
  }

  dosyaGuncelle(dosyaBilgileri: TamamlanmayanDosyalarRow) {
    this.tevziBilgileri.dosyaId = dosyaBilgileri.dosyaID;
    this.dosyaBilgileri = dosyaBilgileri;
  }

  async runQuery(): Promise<void> {
    try {
      this.setStatus(TakipAcStatusEnum.OPEN_PROCESSING);

      for (const taraf of this.parsedFile.tarafList) {
        try {
          await taraf.runQuery();
        } catch (e) {
          this.setStatus(TakipAcStatusEnum.QUERY_FAIL);
          let tarafIndex = this.parsedFile.tarafList.indexOf(taraf);
          this.parsedFile.tarafList.splice(tarafIndex, 1);
          console.log("tarafList HATA", e);
          //@ts-ignore
          throw new Error(e.message);
        }
      }
      await this.runQueryForHarcListesi();
      this.setStatus(TakipAcStatusEnum.QUERY_SUCCESS);
      if (this.parsedFile.isIlamli()) {
        try {
          this.ekler.ilamliDosya = await this.ilamliDosyaTarafRunQuery();
          this.parsedFile.ilamSorgusu.status = true;
        } catch (e) {
          this.parsedFile.ilamSorgusu.status = false;
          this.parsedFile.ilamSorgusu.message =
            "İlam Sorgusu Hatası: " + e.message;
        }
      }
      return;
    } catch (e) {
      this.setStatus(TakipAcStatusEnum.QUERY_FAIL);
      console.log("ERR RUNQUERY");
      console.log(e);
      //@ts-ignore
      throw new Error(e.message);
    }
  }

  async ilamliDosyaTarafRunQuery(): Promise<{ message: string } | undefined> {
    try {
      console.log("ilamliDosyaTarafRunQuery parsedFile", this.parsedFile);
      let ilam: Ilam = this.parsedFile.getIlamBilgileri();
      console.log("parsedFile.getIlamBilgileri", ilam);
      let sorgulanacakIlamTurleri = [0, 1, 2, "0", "1", "2"];
      if (
        sorgulanacakIlamTurleri.includes(ilam.ilamBilgileri.ilamTuru) &&
        !(
          ilam.ilamBilgileri.dosyaYil &&
          ilam.ilamBilgileri.dosyaSira &&
          ilam.birimId
        )
      ) {
        return Promise.reject({
          message: "İlam bilgisi eksik veya girilmemiş.",
        });
      }
      if (sorgulanacakIlamTurleri.includes(ilam.ilamBilgileri.ilamTuru)) {
        let ilamliErr: any = null;
        try {
          let uygunMu = await app.$uyap.IcraTakipIlamDosyasiUygunMu().run({
            birimId: ilam.birimId,
            ilamTuru: Number(ilam.ilamBilgileri.ilamTuru),
            dosyaTurKod: Number(ilam.dosyaTurKod),
            dosyaYil: Number(ilam.ilamBilgileri.dosyaYil),
            dosyaSiraNo: Number(ilam.ilamBilgileri.dosyaSira),
            dosyaNo:
              ilam.ilamBilgileri.dosyaYil + "/" + ilam.ilamBilgileri.dosyaSira,
            TarafList: JSON.stringify(this.parsedFile.tarafList),
          });
          if (uygunMu) {
            return Promise.resolve(undefined);
          }
        } catch (err) {
          this.addMessage(
            "error",
            "ilamDosyaTarafCheckIterator | taraflar eşleşmedi, sonrakini deneyelim"
          );
          console.log("err", err);
          ilamliErr = err;
        }
        return Promise.reject(ilamliErr);
      } else {
        console.log("İlam sorgusu yapma.");
        return Promise.resolve(undefined);
      }
      return Promise.reject({ message: "İlamlı dosya bulunamadı." });
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async takipAc(isHizli: boolean = false): Promise<void> {
    try {
      //1 ilk asama 2 evrak gonderilmis asama
      if (isHizli || this.dosyaBilgileri.surecNo == 1) {
        this.setStatus(TakipAcStatusEnum.OPEN_PROCESSING);
        await this.vekaletKontrol();
        await this.dayanakKontrol();
        await this.getImzasizUdfFromUyap(this.tevziBilgileri.dosyaId);
        console.log("this", this);
        if (this.harcMasrafToplam == 0) {
          //harc muafli takip acilmissa odeme yapmiyor...
          await this.takipAcilisEvraklariniGonder(this.tevziBilgileri.dosyaId);
          // TODO: Burada hata olabilir messageda html data gelmiyor
          await this.setTakipBilgileriFromTakipAcilisEvraklariResponse();
        } else {
          console.log("EVRAK GONDER");
          await this.takipAcilisEvraklariniGonder(this.tevziBilgileri.dosyaId);
          console.log("ODEME YAP");
          await this.odemeYap();
        }
      } else if (this.dosyaBilgileri.surecNo == 2) {
        console.log("ODEME YAP");
        await this.odemeYap();
      } else {
        return Promise.reject({
          message: "Bilinmeyen süreç numarası takip açılamadı.",
        });
      }
      this.setStatus(TakipAcStatusEnum.OPEN_SUCCESS);

      try {
        if (this.parsedFile.formData.tebligatGideri.enabled == true)
          await this.tebligatGideriOde(this.tevziBilgileri.dosyaId);
      } catch (e) {
        this.addMessage(
          "error",
          "Takip Açıldı " + this.takipBilgileri.dosyaEsasNo + ", Tebligat Gideri Ödemesi Yapılamadı : " + e.message
        );
        this.setStatus(TakipAcStatusEnum.TAHSILAT_FAIL);
      }
      return this.setTakipBilgileriToIcraPro();
    } catch (e) {
      this.addMessage("error", "Takip Açılamadı - " + e);
      this.setStatus(TakipAcStatusEnum.OPEN_FAIL);
      return Promise.reject(e);
    }
  }

  tamamlanmamisDosyaTakipAc(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        if (this.dosyaBilgileri.surecNo == SurecNoEnum.IslemYapilmamis) {
          await this.vekaletKontrol();
          await this.dayanakKontrol();
          this.setStatus(TakipAcStatusEnum.OPEN_PROCESSING);
          await this.getImzasizUdfFromUyap(this.dosyaBilgileri.dosyaID);
          await this.takipAcilisEvraklariniGonder(this.dosyaBilgileri.dosyaID);

          let masrafHesabi = app.$uyap.DosyaHarcMasrafHesabi().run({
            dosyaId: this.dosyaBilgileri.dosyaID,
            dosyaTurKod: 35,
            tcKimlikNo: '',
          });
          await this.odemeYap();
          if (this.parsedFile.formData.tebligatGideri.enabled == true)
            await this.tebligatGideriOde(this.tevziBilgileri.dosyaId);
        } else if (
          this.dosyaBilgileri.surecNo == SurecNoEnum.OdemeIcinBekliyor
        ) {
          //odeme yap
          await this.odemeYap();
        } else {
          //islem yapma
        }
        this.setStatus(TakipAcStatusEnum.OPEN_SUCCESS);
        return resolve();
      } catch (e) {
        this.setStatus(TakipAcStatusEnum.OPEN_FAIL);
        console.log("tamamlanmamisDosyaTakipAc Error = ", e);
        return reject(e);
      }
    });
  }

  async runQueryForHarcListesi(): Promise<any> {
    try {
      this.addMessage("info", "Harç hesaplanıyor!");
      console.log("parsedfile", this.parsedFile);
      let jsonData = this.parsedFile.toUyapJson();
      console.log("jsonData", jsonData);
      let harcHesaplaResult = await app.$uyap.JsonHarcHesapla().run(jsonData);
      this.harcMiktar = harcHesaplaResult.harclar
        .filter((item) => item.harcMasraf === "H")
        .reduce((acc, item) => acc + item.hesapMiktar, 0);
      this.masrafMiktar = harcHesaplaResult.harclar
        .filter((item) => item.harcMasraf === "D")
        .reduce((acc, item) => acc + item.hesapMiktar, 0);
      this.harcMasrafToplam = harcHesaplaResult.toplam;
      this.harcMasrafList = harcHesaplaResult.harclar;
      this.parsedFile.harcMasrafToplam = harcHesaplaResult.toplam;
      this.addMessage("info", "Harç Hesaplandı!");
      return Promise.resolve();
    } catch (e) {
      this.addMessage("error", "Harç Hesaplanamadı!");
      return Promise.reject(e);
    }
  }

  async tebligatGideriOde(dosyaId: string): Promise<TebligatGideriOdeResult> {
    try {
      let dosyaTarafBilgileriCevap = await app.$uyap
        .GetDosyaTarafBilgileri()
        .run({ dosyaId: dosyaId });

      let alacakli = dosyaTarafBilgileriCevap.find(
        (x) => x.dosyaKisiDVO.tarafRolu == TarafRol.ALACAKLI
      );
      let borclular = dosyaTarafBilgileriCevap.filter(
        (x) => x.dosyaKisiDVO.tarafRolu == TarafRol.BORCLU
      );

      let adet = borclular.length;

      let tutar = this.parsedFile.formData.tebligatGideri.tutar
        .toString()
        .replace(",", ".");
      let aciklama = this.parsedFile.formData.tebligatGideri.aciklama;
      //@ts-ignore
      let odenecekTutar = parseFloat(adet * tutar).toFixed(2);
      //Borclu sayisi kadar adet giriliyor.
      let alacakliAdi = "";
      if (!alacakli) {
        throw new Error("Tebligat gideri ödenirken alacaklı bulunamadı.");
      }
      if (alacakli.dosyaKisiDVO.kisiMiKurumMu == 0)
        alacakliAdi =
          alacakli.dosyaKisiDVO.kisiBilgDVO.adi +
          " " +
          alacakli.dosyaKisiDVO.kisiBilgDVO.soyadi;
      else alacakliAdi = alacakli.dosyaKisiDVO.kurumBilgDVO.kurumAdi as string;
      let requestObject = {
        dosyaId: dosyaId,
        tarafDVO: "null",
        harcMasrafTipi: "T",
        harcMasrafList:
          "T**1**" +
          adet +
          "**" +
          odenecekTutar +
          "**Masraf Avansı Tahsilatı**" +
          aciklama +
          "**false**0**" +
          alacakliAdi +
          "**" +
          alacakli.dosyaKisiDVO.ilkKisiKurumId +
          "**21**0**false**0",
        postaMasraflariList: [],
        odemeTipi: "2",
        uyapId: "",
        vakifbankHesapBilgileri: "null",
      };
      console.log("DosyaOdemeIslemleri requestObject", requestObject);
      await this.delay(20);
      let odendi = await app.$uyap.DosyaOdemeIslemleri().run(requestObject);
      this.addMessage("success", "Tebligat gideri ödeme başarılı.");
      return {
        result: odendi,
        tutar: odenecekTutar,
        adet: adet,
      };
    } catch (e) {
      throw new Error(e.message);
    }
  }

  delay(time: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, time * 1000));
  }

  async odemeYap(): Promise<any> {
    try {
      // @ts-ignore
      let alacakli: any = this.parsedFile.tarafList.find((val, key) =>
        val.isAlacakli()
      );
      console.log("ODEME YAP RUN");
      let odemeRequestData: any;
      if (this.parsedFile.formData.odemeTipi == OdemeTipiEnum.Barokart) {
        odemeRequestData = {
          dosyaId: this.tevziBilgileri.dosyaId,
          kisiKurumId: alacakli.temelBilgileri.kisiKurumID,
          odemeTipi: OdemeTipiEnum.Barokart, //barokart
          vakifbankHesapBilgileri: "null",
          harcMasrafTipi: "",
          harcMasrafList: "",
          postaMasraflariList: "",
        };
      }
      if (this.parsedFile.formData.odemeTipi == OdemeTipiEnum.Vakifbank) {
        let vakifbank = new VakifbankOdeme();
        await vakifbank.run(this.harcMasrafToplam);
        // TODO: kullanılacak ise Betaya göre güncellenmeli
        odemeRequestData = vakifbank.takipAcOdemeYapToUyapJson(
          this.tevziBilgileri.dosyaId,
          alacakli.temelBilgileri.kisiKurumId,
          ""
        );
      }
      let odemeResult = await app.$uyap.OdemeYap().run(odemeRequestData);
      console.log("ODEME YAP RESULT", odemeResult);
      if (odemeResult.type == "success") {
        this.takipBilgileri.dosyaEsasNo = odemeResult.dosyaEsasNo;
        this.takipBilgileri.icraDairesi = odemeResult.icraDairesi;
        this.addMessage("success", "Takip açıldı.");
        this.setStatus(TakipAcStatusEnum.OPEN_SUCCESS);
      } else {
        this.addMessage("error", "Ödeme hatası - " + odemeResult.message);
        this.setStatus(TakipAcStatusEnum.OPEN_FAIL);
      }
      setTimeout(() => {
        return Promise.resolve();
      }, 1500);
    } catch (e) {
      console.log("odemeYap HATA", e);
      this.addMessage("error", "Hata - " + e.message);
      this.setStatus(TakipAcStatusEnum.OPEN_FAIL);
      return Promise.reject(e);
    }
  }

  async tevziAl(): Promise<void> {
    this.setStatus(TakipAcStatusEnum.PREPARE_PROCESSING);
    try {
      await this.mukerrerDosyaKontrol(this.parsedFile.hash, "xml");
      this.addMessage("info", "Tevzi alınıyor!");
      this.tevziBilgileri = await app.$uyap
        .TevziAl()
        .run(this.parsedFile.toUyapJson());
      this.addMessage("info", "Takip talebi hazırlanıyor!");
      await this.getImzasizUdfFromUyap(this.tevziBilgileri.dosyaId);
      this.addMessage("success", "Takip talebi hazırlandı!");
      this.setStatus(TakipAcStatusEnum.PREPARE_SUCCESS);
      await this.tevziAlinmisDosyaKaydet();
      await this.mukerrerDosyaKontrolFromTakipTalebi();
      return Promise.resolve();
    } catch (e) {
      console.log("TEVZİ AL ERR", e);
      this.setStatus(TakipAcStatusEnum.PREPARE_FAILED);
      this.addMessage("error", e.message);
      if (e.response?.status == 422 && e.response.data.message)
        return Promise.reject(
          new Error(e.response.data.message + " Hash: #" + e.response.data.hash)
        );
      return Promise.reject(e);
    }
  }

  async getDosyaEvraklar() {
    console.log("this.tevziBilgileri", this);
    if (!this.evraklar)
      this.evraklar = await app.$uyap
        .DosyaEvraklar()
        .run({ dosyaId: this.tevziBilgileri.dosyaId });
    console.log("evraklar", this.evraklar);
  }

  async takipTalebiIndir() {
    let evrakBlob: Blob = await this.getEvrakBlobFromType("Takip Talebi");
    //@ts-ignore
    saveAs(evrakBlob, evrakBlob.name);
  }
  async vekaletnameIndir() {
    let evrakBlob: Blob = await this.getEvrakBlobFromType("Vekaletname");
    //@ts-ignore
    saveAs(evrakBlob, evrakBlob.name);
  }
  async tahsilHarciMakbuzuIndir() {
    let evrakBlob: Blob = await this.getEvrakBlobFromType(
      "Tahsil Harcı Makbuzu(Sayman Mutemet Alındısı)"
    );
    //@ts-ignore
    saveAs(evrakBlob, evrakBlob.name);
  }

  async vekaletPuluIndir() {
    let evrakBlob: Blob = await this.getEvrakBlobFromType(
      "Vekalet Pulu Makbuzu"
    );
    //@ts-ignore
    saveAs(evrakBlob, evrakBlob.name);
  }

  async getEvrakBlobFromType(type: string): Promise<Blob> {
    await this.getDosyaEvraklar();
    //@ts-ignore
    let evrak = this.evraklar.son20Evrak.find(
      (item: IDosyaEvrak) => item.tur == type
    );
    if (evrak) {
      return await app.$uyap.EvrakIndir().run({
        dosyaId: evrak.dosyaId,
        evrakId: evrak.evrakId,
        yargiTuru: '',
      });
    } else {
      throw new Error("Evrak Bulunamadı");
    }
  }

  convertToISOFormat(dateStr: string) {
    // dateStr -> 09/05/2024 10:43:06
    const parts = dateStr.split(" "); // Boşluklardan böl
    const dateParts = parts[0].split("/"); // Tarihi '/' işaretlerinden böl
    const timeParts = parts[1].split(":"); // Saati ':' işaretlerinden böl

    const isoDateStr = `${dateParts[2]}-${dateParts[1]}-${
      dateParts[0]
    }T${timeParts.join(":")}`;
    return isoDateStr;
  }

  async tevziAlinmisDosyaKaydet(): Promise<any> {
    ///v1/uyap-takip
    let uyap_id =
      this.tevziBilgileri.birimID +
      "/" +
      this.convertToISOFormat(this.tevziBilgileri.dosyaAcilisTarihi);
    return await app.$http.post("/api/v1/uyap-takip/", {
      hash: this.parsedFile.hash,
      type: "xml",
      dosya: this,
      uyap_dosya_id: this.tevziBilgileri.dosyaId,
      uyap_id: uyap_id,
      uyap_id_hash: btoa(uyap_id),
      vekalet_id: this.parsedFile.vekaletObject?.id,
      dayanak: this.parsedFile.dayanaklar,
      status: 1,
    });
  }

  async getTevziAlinmisDosyalar() {
    let dosyalar = await app.$http.get("/api/v1/uyap-takip");
    console.log("dosyalar", dosyalar);
  }

  vekaletKontrol(): Promise<Blob> {
    return new Promise(async (resolve, reject) => {
      try {
        if (!this.getVekaletBlob() || this.getVekaletBlob() == null) {
          if (this.parsedFile.vekaletObject) {
            this.vekalet = await app.$uyapHelper.getBlobFromLink(
              "/api/v1/file/download/" +
                this.parsedFile.vekaletObject.file_path.replace("uploads/", "")
            );
          } else {
            let message = "Vekalet eklenmemiş lütfen vekalet ekleyiniz!";
            this.addMessage("error", message);
            return reject({ message: message });
          }
        }
        return resolve(this.getVekaletBlob());
      } catch (e) {
        this.addMessage("error", e.message);
        return reject({ message: e.message });
      }
    });
  }

  async dayanakKontrol(): Promise<void> {
    let sorgulanacakIlamTurleri = [3, 4, 5, 6, "3", "4", "5", "6"];
    if (
      this.parsedFile.isIlamli() &&
      sorgulanacakIlamTurleri.includes(
        this.parsedFile.getIlamBilgileri().ilamTuru
      ) &&
      this.parsedFile.dayanaklarObject.length == 0
    )
      throw new Error(
        "İlamı veren kurum  Noter/Arabulucu/Hakem Heyeti/Diğer ise dayanak eklemek zorunludur."
      );
    for (const dayanak of this.parsedFile.dayanaklarObject) {
      let blob = await app.$uyapHelper.getBlobFromLink(
        "/api/v1/file/download/" + dayanak.file_path.replace("uploads/", "")
      );
      this.dayanaklar.push(blob);
    }
  }

  isEicraprodanmiAcilmis() {
    if (this.parsedFile.hash == "" || this.parsedFile.hash == null)
      return false;
    return true;
  }
}