import {UyapRequest} from "./UyapRequest";

export interface IcraPesinHarcGetirTalep {
    dosyaId: string | number
}

export interface IcraPesinHarcGetirCevap {
    pesinHarcDusulsunmu: boolean
    dusulenPesinHarc: number
    nafakaIleIliskilimi: boolean
    pesinHarcSerhMi: boolean
}

export default class IcraPesinHarcGetir extends UyapRequest<IcraPesinHarcGetirTalep, IcraPesinHarcGetirCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "IcraPesinHarcGetir_" + this.talepData.dosyaId
        };
    }

    getMockResponse(): any {
        return {
            "pesinHarcDusulsunmu": false,
            "dusulenPesinHarc": 0.5,
            "nafakaIleIliskilimi": false,
            "pesinHarcSerhMi": false
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'icraPesinHarcGetir.ajx';
    }

    async parse(data: any): Promise<IcraPesinHarcGetirCevap> {
        return data
    }
}

