export class ITabloParser<T> {
    data: Array<T> = [];
    found: boolean = false;
    message: string = "";
    html: string = "";
}

export interface IIcerikAl {
    baslik: string,
    icerik: string
}

export interface AdresRow {
    ilKodu: number,
    ilAdi?: string,
    ilceAdi?: string,
    ilceKodu: number,
    adres: string,
    kisiKurumID: number,
    adresID: number,
    adresTuru: string,
    adresTuruAciklama: string,
    eklenebilirAdresMi: boolean,
    kayitliAdresMi: boolean,
    aktifAdresmi: boolean,
    guncellendiMi: boolean,
    aktif?: string
}

export interface IAdresDVORow {
    id: number,
    adresId: number,
    dosyaId: string,
    ilkKisiKurumId: number,
    guncelKisiKurumId: number,
    gizliYazismaAdresi: boolean,
    kisikurumtur: string,
    adresDVO: AdresRow
}

export interface MahkemeBirimleriRow {
    birimAdi: string,
    birimId: number,
    evraginBirimDVODaGonderilecegiBirimBilgisi: number,
    yeniBirimEkle: boolean,
    orgKoduDegisti: boolean,
    eskiAd: string,
    taranmamisKaydetme: string,
    testMi: boolean
}

export interface ISorguSonuc<ISonuc> {
    borcluBilgileri: IBorcluBilgileri,
    sorgulayanBilgileri: string,
    portalTuru: number,
    dosyaOzetDVO: IDosyaOzetDVO,
    sorguTarihi: string,
    sorguSonucDVO?: ISonuc,     // sorguSonucu true ise
    sorgulamaTuru: string,
    sorguSonucu: boolean,
    hasError: boolean,
    hasData: boolean,
    isMock: boolean,
    message: string             // parserda oluşturulacak
}

export interface IBorcluBilgileri {
    kisiTumDVO?: IKisiTumDVO,
    kurumDVO?: IKurumDVO,
    kisiKurumId: number,
    turu: BorcluTur
}

export enum BorcluTur {
    KISI = 0,
    KURUM = 1
}

export interface IKisiTumDVO {
    olumKaydi: boolean,
    isAvukat: boolean,
    isNoter: boolean,
    isTarafUyari: boolean,
    isHakimSavci: boolean,
    mernisDegisiklikVarmi: boolean,
    mernisDegisiklikNedeni: number,
    sorguTuru: string,
    tcKimlikNo: string,
    adi: string,
    soyadi: string,
    babaAdi: string,
    anaAdi: string,
    dogumTarihiStr: string,
    aktifMernisAdresiSorgusu: boolean,
    hasOlumKaydi: boolean
}

export interface IKurumDVO {
    faaliyetDurumu: number,
    kurumAdi: string,
    mersisIlKodu: number,
    mersisIlceKodu: number,
    tacirMi: string,
    vergiNo: string,
    veriGelisYeri: number
}

export interface IDosyaOzetDVO {
    birimDVO: IBirimDVO,
    rolIdList: [],
    isBirimKapali: boolean,
    dosyaTurKod: number,
    dosyaTurAd: string,
    dosyaNo: string,
    dosyaNoYil: number,
    dosyaSira: number,
    dosyaId: string,
    dosyaAcilisTarihi: string,
    genelDosyaDurumu: string,
    genelDosyaDurumuAsInteger: number,
    taraflar: string,
    vekilId: number,
    vekilMi: boolean,
    vekilHazineAvukatiMi: boolean
}

export interface IBirimDVO{
    birimAdi: string,
    birimId: string,
    orgKodu: string,
    duzey1: string,
    duzey2: string,
    duzey3: string,
    duzey4: string,
    duzey5: string,
    birimTuru1: string,
    birimTuru2: string,
    birimTuru3: string,
    evraginBirimDVODaGonderilecegiBirimBilgisi: number,
    yeniBirimEkle: boolean,
    orgKoduDegisti: boolean,
    isTumunuKopyala: boolean,
    eskiAd: string,
    eskiOrgKod: string,
    taranmamisKaydetme: string,
    testMi: number
}

export interface IAvukatDosya {
    dosyaId: string,
    dosyaNo: string,
    dosyaDurumKod: number,
    dosyaDurum: string,
    task: any,
    dosyaTurKod: number,
    dosyaTur: string,
    dosyaAcilisTarihi: UyapTarih,
    birimAdi: string,
    birimId: string,
    birimTuru1: string,
    birimTuru2: string,
    birimTuru3: string,
    isDavaDosyasiAcilmisMi: boolean,
    isNew: boolean,
    dosyaKapanisTarihi?: UyapTarih
}

export interface UyapTarih {
    date: UyapDate,
    time: UyapTime
}

interface UyapDate {
    year: number,
    month: number,
    day: number
}

interface UyapTime {
    hour: number,
    minute: number,
    second: number,
    nano: number
}

export interface IHarcMasraf {
    harcmasrafKodStr?: number,
    harcmasrafBedelmiOranmi?: string,
    harcMasrafBedel?: number,
    hesapMiktar: number,
    ekranID?: number,
    pesinHarcDusulsunmu: string,
    hmDurumu: number,
    hmAktif: boolean,
    isSatirDegisecek: boolean,
    harcMasrafKod?: number,
    harcMasrafAdi: string,
    aciklama?: string,
    baslangicHarci?: string,
    harcMasraf: string,
    harcTip?: number,
    gv_tabi: number,
    dv_tabi: number,
    yg_tabi: number,
    makbuzTipi?: number,
    harcMasrafOran?: number,
    harcMasrafAltSiniri?: number
}