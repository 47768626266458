import {UyapRequest} from "./UyapRequest";
import app from "@/main";
import {ISorguSonuc} from "./CommonInterfaces";

export interface BorcluPostaCekiTalep {
    dosyaId: string | number,
    kisiKurumId: number,
    sorguBakiye: number | string,
}

export interface BorcluPostaCekiCevap extends ISorguSonuc<Array<IPostaCekiSonuc>>{
}

export interface IPostaCekiSonuc {
    adi?: string,
    anaAd: string,
    babaAd: string,
    dogumTarih?: string,
    hacizKoyan?: string,
    hacizTutar?: number,
    hesapBakiye: number,
    hesapSubeAdi?: string,
    kimlikNo?: number,
    soyad?: string,
    unvan?: string,
    vergiNo?: number,
}

export default class BorcluPostaCeki extends UyapRequest<BorcluPostaCekiTalep, BorcluPostaCekiCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "borcluPostaCeki_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getMockResponse(): any {
        return {"borcluBilgileri":{"kisiTumDVO":{"olumKaydi":false,"isAvukat":false,"isNoter":false,"isTarafUyari":false,"isHakimSavci":false,"mernisDegisiklikVarmi":false,"mernisDegisiklikNedeni":"","sorguTuru":0,"tcKimlikNo":"4********10","adi":"AH**** MÜ***","soyadi":"YA*******","babaAdi":"A*********","anaAdi":"Ş*****","dogumTarihiStr":"1957","aktifMernisAdresiSorgusu":true,"hasOlumKaydi":false},"kisiKurumId":28698317,"turu":0},"sorgulayanBilgileri":"Av.NİHAN ERDOĞAN","portalTuru":2,"dosyaOzetDVO":{"birimDVO":{"birimAdi":"Ankara 2. Genel İcra Dairesi","birimId":"1000963","orgKodu":"1.04.011.000.6037","duzey1":"1","duzey2":"04","duzey3":"011","duzey4":"000","duzey5":"6037","birimTuru1":"11","birimTuru2":"1101","birimTuru3":"1199","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},"rolIdList":[],"isBirimKapali":false,"dosyaTurKod":35,"dosyaTurAd":"İcra Dosyası","dosyaNo":"2023/27440","dosyaNoYil":2023,"dosyaSira":27440,"dosyaId":"013@RlwAzAkR3QHUdOJ2IvVxsoNpicN8FgM2Tp@94ygIgWCXZiMptu8uTJVX0bQm","dosyaAcilisTarihi":"08/07/2023 10:35","genelDosyaDurumu":"Açık","genelDosyaDurumuAsInteger":0,"taraflar":"ABDULSELAM DENİZ, AHMETİ MÜNÜR YALÇINALP","vekilId":0,"vekilMi":false,"vekilHazineAvukatiMi":false},"sorguTarihi":"25/04/2024 10:34","sorguSonucDVO":[{"ad":"AHMETİ MÜNÜR","anaAd":"","babaAd":"ABDULKADİR","dogumTarih":"1957/08/25","hacizKoyan":"","hacizTutar":0.00,"hesapBakiye":0.00,"hesapSubeAd":"","kimlikNo":47161224210,"soyad":"YALÇINALP","unvan":"  ","vergiNo":0}],"sorgulamaTuru":"Posta Çeki","sorguSonucu":true,"hasError":false,"hasData":false,"isMock":false}
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'borclu_bilgileri_goruntule_posta_ceki.ajx';
    }

    async parse(data: any): Promise<BorcluPostaCekiCevap> {
        data.message = "Kişiye ait posta çeki kaydı " + (data.sorguSonucu ? 'var' : 'yok');
        return data
    }

}

