import {DosyaTaraflarCevap} from "./DosyaTarafBilgileri";
import app from "@/main";
import {DosyaSafahatCevap, ISafahat} from "./DosyaSafahat";
import {TahsilatReddiyatCevap} from "./TahsilatReddiyat";
import {DosyaEvraklarCevap} from "./DosyaEvraklar";
import {DosyaDetayBilgileriCevap} from "./DosyaDetayBilgileri";
import {DosyaHesapBilgileriCevap} from "./DosyaHesapBilgileri";
import {MtsTebligatListCevap} from "./MtsTebligatList";
import TakipDosya from "./classes/TakipDosya";

export class AvukatDosya {
    dosya: TakipDosya;
    taraflar: Taraflar = new Taraflar();
    safahat: DosyaSafahatCevap | EmptyObject = new EmptyObject();
    tahsilatReddiyat: TahsilatReddiyatCevap | EmptyObject = new EmptyObject();
    evraklar: DosyaEvraklarCevap | EmptyObject = new EmptyObject();
    dosyaDetayBilgileri: DosyaDetayBilgileri | EmptyObject = new EmptyObject();
    dosyaHesapBilgileri: DosyaHesapBilgileri | EmptyObject = new EmptyObject();
    mtsTebligatList: MtsTebligatListCevap = new MtsTebligatListCevap();
    barkod: Barkodlar| EmptyObject = new EmptyObject();


    constructor(dosya: TakipDosya) {
        this.dosya = dosya;
    }

    async queryTaraflar(): Promise<void> {
        try {
            this.taraflar.items = await app.$uyap.DosyaTarafBilgileri().run({
                dosyaId: this.dosya.dosyaId
            });
            this.taraflar.message = "Sorgulama Başarılı";
            return Promise.resolve();
        } catch (e) {
            this.taraflar.message = e.message;
            return Promise.reject(e);
        }
    }

    async querySafahat(): Promise<void> {
        try {
            this.safahat = await app.$uyap.DosyaSafahat().run({
                dosyaId: this.dosya.dosyaId,
                token: ''
            });
            return Promise.resolve();
        } catch (e) {
            this.safahat.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryTahRed(): Promise<void> {
        try {
            this.tahsilatReddiyat = await app.$uyap.TahsilatReddiyat().run({
                dosyaId: this.dosya.dosyaId,
                dosyaTurKod: 1
            });
            return Promise.resolve();
        } catch (e) {
            this.tahsilatReddiyat.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryEvraklar(): Promise<void> {
        try {
            this.evraklar = await app.$uyap.DosyaEvraklar().run({
                dosyaId: this.dosya.dosyaId
            });
            return Promise.resolve();
        } catch (e) {
            this.evraklar.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryDosyaBilgileri(): Promise<void> {
        try {
            let data = await app.$uyap.DosyaDetayBilgileri().run({
                dosyaId: this.dosya.dosyaId
            });
            this.dosyaDetayBilgileri = new DosyaDetayBilgileri(data,"Sorgulama Başarılı");
            return Promise.resolve();
        } catch (e) {
            this.dosyaDetayBilgileri.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryDosyaHesapBilgileri(): Promise<void> {
        try {
            let items = await app.$uyap.DosyaHesapBilgileri().run({
                dosyaId: this.dosya.dosyaId
            });
            this.dosyaHesapBilgileri = new DosyaHesapBilgileri(items,"Sorgulama Başarılı");
            return Promise.resolve();
        } catch (e) {
            this.dosyaHesapBilgileri.message = e.message;
            return Promise.reject(e);
        }
    }

    async queryBarkod(): Promise<void> {
        try {
            if (!(this.safahat as DosyaSafahatCevap).safahatlar)
                await this.querySafahat();
            let filtered = (this.safahat as DosyaSafahatCevap).safahatlar.filter(
                // Tebligat Bilgileri Kaydedildi
                item => item.safahatTuruKod == 13
            );
            this.barkod = new Barkodlar(filtered, "Sorgulama Başarılı");
            return Promise.resolve();
        } catch (e) {
            this.barkod.message = e.message;
            return Promise.reject(e);
        }
    }

    // TODO :servis güncel değil
    async queryMtsTebligat(): Promise<void> {
        try {
            this.mtsTebligatList = await app.$uyap.MtsTebligatList().run({
                dosyaId: this.dosya.dosyaId
            });
            this.mtsTebligatList.message = "Sorgulama Başarılı";
            return Promise.resolve();
        } catch (e) {
            this.mtsTebligatList.message = e.message;
            return Promise.reject(e);
        }
    }
}

class EmptyObject {
    message: string = "";
}

class Taraflar {
    message: string = "";
    items: DosyaTaraflarCevap = [];
}

class DosyaDetayBilgileri {
    message: string;
    data: DosyaDetayBilgileriCevap;

    constructor(data: DosyaDetayBilgileriCevap, message: string) {
        this.data = data;
        this.message = message;
    }
}

class DosyaHesapBilgileri {
    message: string;
    items: DosyaHesapBilgileriCevap;

    constructor(items: DosyaHesapBilgileriCevap, message: string) {
        this.items = items;
        this.message = message;
    }
}

class Barkodlar {
    items: Array<ISafahat>;
    message: string;

    constructor(items: Array<ISafahat>, message: string) {
        this.items = items;
        this.message = message;
    }
}