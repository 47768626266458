import {UyapRequest} from "./UyapRequest";
import app from "@/main";
import {ISorguSonuc} from "./CommonInterfaces";

export interface SgkKamuTalep {
    dosyaId: string | number,
    kisiKurumId: number,
    sorguBakiye: number | string,
}

export interface SgkKamuCevap extends ISorguSonuc<ISgkKamuSonuc>{}

export interface ISgkKamuSonuc {
    adi: string,
    durumAciklamasi4C: string,
    durumKapsami4C: string,
    iseBaslamaTarihi: string,
    kurum: string,
    kurumIl: string,
    kurumIlce: string,
    soyadi: string,
    tckn: number,
    unvani: string,
    hataKodu: 0 | 995,      // başarılı | kayıt bulunamadı
    hataMesaji: string
}


export class SgkKamu extends UyapRequest<SgkKamuTalep, SgkKamuCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "SgkKamu_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getMockResponse(): any {
        return {"borcluBilgileri":{"kisiTumDVO":{"olumKaydi":false,"isAvukat":false,"isNoter":false,"isTarafUyari":false,"isHakimSavci":false,"mernisDegisiklikVarmi":false,"mernisDegisiklikNedeni":"","sorguTuru":0,"tcKimlikNo":"1********08","adi":"OS***","soyadi":"SÜ***","babaAdi":"Ö***","anaAdi":"Z*****","dogumTarihiStr":"1965","aktifMernisAdresiSorgusu":true,"hasOlumKaydi":false},"kisiKurumId":28126274,"turu":0},"sorgulayanBilgileri":"Av.NİHAN ERDOĞAN","portalTuru":2,"dosyaOzetDVO":{"birimDVO":{"birimAdi":"Ankara 1. Genel İcra Dairesi","birimId":"1000967","orgKodu":"1.04.011.000.6036","duzey1":"1","duzey2":"04","duzey3":"011","duzey4":"000","duzey5":"6036","birimTuru1":"11","birimTuru2":"1101","birimTuru3":"1199","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},"rolIdList":[],"isBirimKapali":false,"dosyaTurKod":35,"dosyaTurAd":"İcra Dosyası","dosyaNo":"2011/13816","dosyaNoYil":2011,"dosyaSira":13816,"dosyaId":"TJaln9DfOiYK@cr4dIpLXzmc8mwmjsFUHxv6X58zAlyxRYCADXgT7tC9JLnnRKVr","dosyaAcilisTarihi":"25/10/2011 15:59","genelDosyaDurumu":"Açık","genelDosyaDurumuAsInteger":0,"taraflar":"OSMAN SÜZEN","vekilId":0,"vekilMi":false,"vekilHazineAvukatiMi":false},"sorguTarihi":"18/07/2024 11:17","sorguSonucDVO":{"adi":"OSMAN","durumAciklamasi4C":"Görev Süresinin Sona Ermesi","durumKapsami4C":"Pasif                                             ","iseBaslamaTarihi":"1988-10-27","kurum":"SGK ARSİV DAİRE BASKANLIGI SİCİL ACMA","kurumIl":"ANKARA","kurumIlce":"MERKEZ","soyadi":"SÜZEN","tckn":10984465108,"unvani":"VEKİL ÖĞRETMEN                                                                                      ","hataKodu":0,"hataMesaji":""},"sorgulamaTuru":"Kamu Çalışanı","sorguSonucu":true,"hasError":false,"hasData":false,"isMock":false};
        // return {"borcluBilgileri":{"kisiTumDVO":{"olumKaydi":false,"isAvukat":false,"isNoter":false,"isTarafUyari":false,"isHakimSavci":false,"mernisDegisiklikVarmi":false,"mernisDegisiklikNedeni":"","sorguTuru":0,"tcKimlikNo":"5********36","adi":"ŞE****","soyadi":"KA******* HO*","babaAdi":"M*****","anaAdi":"F****","dogumTarihiStr":"1973","aktifMernisAdresiSorgusu":true,"hasOlumKaydi":false},"kisiKurumId":45310821,"turu":0},"sorgulayanBilgileri":"Av.NİHAN ERDOĞAN","portalTuru":2,"dosyaOzetDVO":{"birimDVO":{"birimAdi":"Ankara 7. Genel İcra Dairesi","birimId":"1000942","orgKodu":"1.04.011.000.6042","duzey1":"1","duzey2":"04","duzey3":"011","duzey4":"000","duzey5":"6042","birimTuru1":"11","birimTuru2":"1101","birimTuru3":"1199","evraginBirimDVODaGonderilecegiBirimBilgisi":0,"yeniBirimEkle":true,"orgKoduDegisti":false,"isTumunuKopyala":false,"eskiAd":"","eskiOrgKod":"","taranmamisKaydetme":"","testMi":0},"rolIdList":[],"isBirimKapali":false,"dosyaTurKod":35,"dosyaTurAd":"İcra Dosyası","dosyaNo":"2023/100840","dosyaNoYil":2023,"dosyaSira":100840,"dosyaId":"qcMErAK0+HfhLN8xDnkwYkJiAmB61VomHOmZpbCYMnARuWRfAb6VGFQW3dcsoNrb","dosyaAcilisTarihi":"11/11/2023 07:49","genelDosyaDurumu":"Açık","genelDosyaDurumuAsInteger":0,"taraflar":"ŞENNUR KARASAKAL HOŞ","vekilId":0,"vekilMi":false,"vekilHazineAvukatiMi":false},"sorguTarihi":"06/05/2024 16:46","sorguSonucDVO":{"tckn":0,"hataKodu":995,"hataMesaji":"Kayıt bulunamadı!"},"sorgulamaTuru":"Kamu Çalışanı","sorguSonucu":true,"hasError":false,"hasData":false,"isMock":false};
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'borclu_bilgileri_goruntule_sgk_kamuCalisaniBilgileri.ajx';
    }

    async parse(data: SgkKamuCevap): Promise<SgkKamuCevap> {
        if (data.sorguSonucDVO?.hataKodu == 995) {
            data.message = data.sorguSonucDVO.hataMesaji;
            data.sorguSonucu = false;
        } else
            data.message = "Sorgulama başarılı";
        return data;
    }

}

export default SgkKamu;
