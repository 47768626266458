import {UyapRequest} from "./UyapRequest";

export interface GonderilebilecekEvrakListesiTalep {
    dosyaId: string | number
}

export type GonderilebilecekEvrakListesiCevap = Array<GonderilebilecekEvrakListesiRow>;

export interface GonderilebilecekEvrakListesiRow {
    turKodu: string,
    turTipi: string,
    turAciklama: string,
    aktif: string,
    aciklama: string,
    ilgiliKisiOlsunMu: string,
    ilgiliEvrakOlsunMu: string,
    islemTipi: string,
    dosyaTipi: string,
    statusYeni: boolean,
    diskeKaydedilebilir: string,
    diskeKaydedilebilirOnayOncesi?: string,
    imzaGerekli?: string,
    indekslensin: boolean,
    yuksekOnemliIs: number,
    onaySonrasiIsOlusturusun: number,
    tebligataCiksinmi: number,
    yuksekMahkemeyeIsDussun: number,
    onayOncesiDagitimDuzenlenebilir: string,
    fizikiGonderilebilir: string,
    disUygulamaAktif: string,
    ekImzasiZorunlumu: string,
    ekListesiDegistirilebilir: string,
    evrakListesindeGorunmesin: string
}

export class GonderilebilecekEvrakListesi extends UyapRequest<GonderilebilecekEvrakListesiTalep, GonderilebilecekEvrakListesiCevap> {
    delayTime = 0;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "GonderilebilecekEvrakListesi_" + this.talepData.dosyaId
        };
    }

    getMockResponse(): any {
        return [[
            {
                "turKodu": "ICR_BLG_TLP",
                "turTipi": "EVRTP00083",
                "turAciklama": "Talep",
                "aktif": "E",
                "aciklama": "100.Madde Bilgi Toplanması Talebi",
                "ilgiliKisiOlsunMu": "F",
                "ilgiliEvrakOlsunMu": "F",
                "islemTipi": "1",
                "dosyaTipi": "0",
                "statusYeni": false,
                "diskeKaydedilebilir": "E",
                "diskeKaydedilebilirOnayOncesi": "E",
                "imzaGerekli": "E",
                "indekslensin": false,
                "yuksekOnemliIs": 0,
                "onaySonrasiIsOlusturusun": 1,
                "tebligataCiksinmi": 0,
                "yuksekMahkemeyeIsDussun": 0,
                "onayOncesiDagitimDuzenlenebilir": "E",
                "fizikiGonderilebilir": "H",
                "disUygulamaAktif": "E",
                "ekImzasiZorunlumu": "H",
                "ekListesiDegistirilebilir": "E",
                "evrakListesindeGorunmesin": "H"
            },
            {
                "turKodu": "ICR_DVT_GON_TALB",
                "turTipi": "EVRTP00083",
                "turAciklama": "Talep",
                "aktif": "E",
                "aciklama": "103 Davetiye Gönderilmesi Talebi",
                "ilgiliKisiOlsunMu": "F",
                "ilgiliEvrakOlsunMu": "F",
                "islemTipi": "1",
                "dosyaTipi": "0",
                "statusYeni": false,
                "diskeKaydedilebilir": "E",
                "diskeKaydedilebilirOnayOncesi": "E",
                "imzaGerekli": "E",
                "indekslensin": false,
                "yuksekOnemliIs": 0,
                "onaySonrasiIsOlusturusun": 1,
                "tebligataCiksinmi": 0,
                "yuksekMahkemeyeIsDussun": 0,
                "onayOncesiDagitimDuzenlenebilir": "E",
                "fizikiGonderilebilir": "H",
                "disUygulamaAktif": "E",
                "ekImzasiZorunlumu": "H",
                "ekListesiDegistirilebilir": "E",
                "evrakListesindeGorunmesin": "H"
            },
            {
                "turKodu": "ICR_21/2_TBL_TLP",
                "turTipi": "EVRTP00083",
                "turAciklama": "Talep",
                "aktif": "E",
                "aciklama": "21/2 Maddeye Göre Tebliğ Talebi",
                "ilgiliKisiOlsunMu": "F",
                "ilgiliEvrakOlsunMu": "F",
                "islemTipi": "1",
                "dosyaTipi": "0",
                "statusYeni": false,
                "diskeKaydedilebilir": "E",
                "diskeKaydedilebilirOnayOncesi": "E",
                "imzaGerekli": "E",
                "indekslensin": false,
                "yuksekOnemliIs": 0,
                "onaySonrasiIsOlusturusun": 1,
                "tebligataCiksinmi": 0,
                "yuksekMahkemeyeIsDussun": 0,
                "onayOncesiDagitimDuzenlenebilir": "E",
                "fizikiGonderilebilir": "H",
                "disUygulamaAktif": "E",
                "ekImzasiZorunlumu": "H",
                "ekListesiDegistirilebilir": "E",
                "evrakListesindeGorunmesin": "H"
            },
            {
                "turKodu": "ICR_TBL_TLP",
                "turTipi": "EVRTP00083",
                "turAciklama": "Talep",
                "aktif": "E",
                "aciklama": "35.Maddeye Göre Tebliğ Talebi",
                "ilgiliKisiOlsunMu": "F",
                "ilgiliEvrakOlsunMu": "F",
                "islemTipi": "1",
                "dosyaTipi": "0",
                "statusYeni": false,
                "diskeKaydedilebilir": "E",
                "diskeKaydedilebilirOnayOncesi": "E",
                "imzaGerekli": "E",
                "indekslensin": false,
                "yuksekOnemliIs": 0,
                "onaySonrasiIsOlusturusun": 1,
                "tebligataCiksinmi": 0,
                "yuksekMahkemeyeIsDussun": 0,
                "onayOncesiDagitimDuzenlenebilir": "E",
                "fizikiGonderilebilir": "H",
                "disUygulamaAktif": "E",
                "ekImzasiZorunlumu": "H",
                "ekListesiDegistirilebilir": "E",
                "evrakListesindeGorunmesin": "H"
            }
        ]];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'dosya_gonderilecek_evrak_listesi_brd.ajx';
    }

    async parse(data: any): Promise<GonderilebilecekEvrakListesiCevap> {
        return data[0];
    }

}

export default GonderilebilecekEvrakListesi;
